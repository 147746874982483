import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import { SuccessPage } from './SuccessPage';
import { useWatch } from "react-hook-form"
import { categories, registerType, veloSampling, cigs, ecigs, availableDevices, model, availableColors, assetSources, neoOffers } from './data';
import { VerifyUser } from "./VerifyUser"
import { NoActiveAction } from './NoActiveActionPage';
import { BirtdayPostalCode, Checkbox, Form, Input, PhoneNumber, Select, sendEvent, Submit } from './Inputs';
import { useWebformStore } from './store';
import { webformSchema } from './schema';
import { useSelector } from 'react-redux';
import { getCurrentAction } from '../../reducers/actions';
import { getUserID } from '../../reducers/user';
import CurrentStep from "./CurrentStep"
import Reciept from './Reciept';
import { selectActions } from '../../reducers/actions';
import AvailableOffers from './AvailableOffers';
import FixedButton from './FixedButton';

function Category(props) {
  const selectedCategory = useWatch({ control: props.control, name: "category" })

  console.log(props.getValues())

  return (
    <>
      <Select {...props} options={categories} name={`category`} label="Źródło konsumenta" placeholder="Wybierz z listy" />
      {["Traditional cigarettes", "E-Cigarettes"].includes(selectedCategory) && <Select  {...props} shouldUnregister={true} options={selectedCategory === "E-Cigarettes" ? ecigs : cigs} name={`secondaryBrand`} label="Nazwa urządzenia" placeholder="Wybierz z listy" />}
    </>
  )
}

function PrevButton(props) {
  const addDataToStore = useWebformStore(state => state.addDataToStore)
  const setOfferChosen = useWebformStore(state => state.setOfferChosen)
  const prevStep = useWebformStore(state => state.prevStep)

  const valuesToSaveOnPrevClick = ["firstName", "lastName", "birthDate", "billingPostalCode", "category", "secondaryBrand",]
  const webformValues = props.getValues(valuesToSaveOnPrevClick)

  function prepareDataToStore() {
    let obj = {}

    valuesToSaveOnPrevClick.forEach((e, index) => {
      obj[e] = webformValues[index]
    })

    return obj
  }

  return (
    <button className='btn white' onClick={e => { e.preventDefault(); addDataToStore(prepareDataToStore()); prevStep(); setOfferChosen(false) }}>
      Cofnij
    </button>
  )
}

export default function WebformNew() {
  const [isSuccess, setIsSuccess] = useState(false);
  const webformData = useWebformStore(state => state.webformData)
  const webformState = useWebformStore(state => state.webformState)
  const isLoading = useWebformStore(state => state.isLoading)
  const isLoadingReceipt = useWebformStore(state => state.isLoadingReceipt)
  const sendForm = useWebformStore(state => state.sendForm)
  const addDataToStore = useWebformStore(state => state.addDataToStore)
  const setCurrentAction = useWebformStore(state => state.setCurrentAction)
  const setUserID = useWebformStore(state => state.setUserID)

  const currentAction = useSelector(getCurrentAction);
  const userID = useSelector(getUserID);

  const actions = useSelector(selectActions);

  const noOTPAction = _.filter(actions.list, (item) => item.status.global.ident === 'started' && item.name.indexOf('exp1707') > -1)[0] || false;

  useEffect(() => {
    setCurrentAction(currentAction)
    setUserID(userID)
  }, [])


  if (!currentAction) {
    return <NoActiveAction />
  }

  console.log(webformState)

  if (webformState.isSuccess) {
    return <SuccessPage />
  }

  if (!webformState.verified) {
    return <VerifyUser />
  }

  if (!webformState.areOffersChosen) {
    return <AvailableOffers />
  }

  return (
    <section className="form-section">
      <CurrentStep />
      <Form onSubmit={data => { addDataToStore(data); sendForm(noOTPAction ? false : true) }} schema={webformSchema} defaultValues={
        { ...webformData, hostessCode: userID, tourPlan: currentAction?.ident, selectedOffers: webformData.selectedOffers }
      }>
        <h3>Twoje dane</h3>
        <Input
          label="Numer ID"
          placeholder=""
          name="hostessCode"
          disabled={true}
        />
        <Input
          label="Numer akcji"
          placeholder=""
          name="tourPlan"
          disabled={true}
        />
        <Input
          label="selectedOffers"
          placeholder=""
          name="selectedOffers"
          disabled={true}
          hidden
        />

        <h3>Dane konsumenta</h3>
        <Input
          label="Imię"
          placeholder="Podaj imię"
          name="firstName"
          onInputBlur={() => { sendEvent("input", "input", "IMIE") }}
        />
        <Input
          label="Nazwisko"
          placeholder="Podaj nazwisko"
          name="lastName"
          onInputBlur={() => { sendEvent("input", "input", "NAZWISKO") }}
        />
        <Input
          label="Adres e-mail"
          placeholder=""
          name="emailAddress"
          disabled={true}
        />

        <PhoneNumber
          disabled={true}
        />

        <BirtdayPostalCode />

        <Category />

        <h3>Zdjęcie paragonu<span>opcjonalnie</span></h3>

        <Reciept />

        <h3>Zgody</h3>

        <Checkbox
          name="ageVerified"
          label="Oświadczam, że jestem pełnoletnim użytkownikiem wyrobów tytoniowych i/lub wyrobów powiązanych. *"
          onInputBlur={() => { sendEvent("checkbox", "click", "ZGODA_WIEK") }}
        />

        <Checkbox
          name="inspirationClub"
          label="Oświadczam, że znam i akceptuję <a href='https://api.inspirationclub.tdy-apps.com/terms/latest' target='_blank' rel='noopener noreferrer'>Regulamin Inspiration Club</a> oraz <a href='https://api.inspirationclub.tdy-apps.com/terms/privacy-policy' target='_blank' rel='noopener noreferrer'>Politykę prywatności*</a>"
          onInputBlur={() => { }}
        />

        <Checkbox
          name="personalDataRegistrationAuthorisation"
          label='Wyrażam zgodę na przetwarzanie podanych przeze mnie danych osobowych przez British American Tobacco Polska Trading sp. z o.o. z siedzibą w Warszawie (dalej jako: "BAT") oraz CHIC spółka z ograniczoną odpowiedzialnością sp.k. z siedzibą w Ostrzeszowie (dalej jako: "CHIC"), jako współadministratorów, w celu przeprowadzenia niepublicznej akcji marketingowej na zasadach określonych w Regulaminie niniejszej akcji, dostępnym pod adresem <a href="https://www.idz.do/regulaminyBAT" target="_blank" rel="noopener noreferrer">www.idz.do/regulaminyBAT</a> oraz potwierdzam zapoznanie się z zasadami dotyczącymi przetwarzania moich danych osobowych, dostępnymi po kliknięciu w niniejszy link: <a href="https://www.idz.do/klauzulaBAT" target="_blank" rel="noopener noreferrer">www.idz.do/klauzulaBAT</a>. *"'
          onInputBlur={() => { sendEvent("checkbox", "click", "ZGODA_DANE") }}
        />

        <p className="consent-text">Wyrażam zgodę na przetwarzanie podanych przeze mnie danych osobowych przez British American Tobacco Polska Trading sp. z o.o. z siedzibą w Warszawie oraz CHIC spółkę z ograniczona odpowiedzialnością z siedzibą w Ostrzeszowie jako współadministratorów, w celu prowadzenia przez te podmioty niepublicznych działań o charakterze marketingu bezpośredniego, z wykorzystaniem profilowania moich danych osobowych oraz badania moich decyzji zakupowych. Działania te będą obejmowały prezentowanie mi informacji o produktach, usługach, konkursach i innych inicjatywach grupy British American Tobacco, w tym prezentowanie spersonalizowanych informacji oraz ofert marketingowych: </p>

        <Checkbox
          name="newsletterCommercialAuthorization"
          label='za pośrednictwem środków komunikacji elektronicznej, tj.: poczty elektronicznej (e-mail) oraz wiadomości tekstowych SMS *'
          onInputBlur={() => { sendEvent("checkbox", "click", "ZGODA_SMSMAIL") }}
        />

        <Checkbox
          name="mobileOptIn"
          label='poprzez kontakt telefoniczny, w tym połączenia głosowe'
          onInputBlur={() => { sendEvent("checkbox", "click", "ZGODA_TEL") }}
        />

        <Checkbox
          name="socialMediaOptIn"
          label='w sekcjach reklamowych na portalach społecznościowych (np. Facebook, Instagram i podobne)'
          onInputBlur={() => { sendEvent("checkbox", "click", "ZGODA_SOCIAL") }}
        />

        <Checkbox
          name="hypercareOptIn"
          label='Wyrażam zgodę na przetwarzanie przez BAT oraz CHIC, jako współadministratorów, moich danych osobowych wskazanych powyżej oraz dodatkowych informacji przekazywanych przeze mnie w ramach ankiet nt. użytkowania produktów oferowanych przez grupę BAT oraz w celu objęcia mnie programem wsparcia posprzedażowego, co wiąże się m.in. z udziałem w badaniach opinii satysfakcji Klienta przeprowadzanych w ramach rozmów telefonicznych oraz za pośrednictwem innych środków komunikacji elektronicznej, w tym na stronach internetowych BAT i CHIC, których jestem zarejestrowanym użytkownikiem.'
          onInputBlur={() => { sendEvent("checkbox", "click", "ZGODA_HYPERCARE") }}
        />

        <h4 className="consent-text">* Zgoda wymagana</h4>
        <p className="consent-text">Pamiętaj, że zawsze możesz wycofać powyższe zgody na przetwarzanie Twoich danych osobowych. Wycofanie zgody nie będzie miało jednak wpływu na zgodność z prawem naszych działań podjętych jeszcze przed jej wycofaniem. Aby wycofać swoją zgodę skontaktuj się z nami pod adresem: daneosobowe@bat.com.pl</p>
        <p className="consent-text">Współadministratorami danych osobowych, o których mowa w powyższym formularzu są British American Tobacco Polska Trading sp. z o.o. z siedzibą w Warszawie (BAT) oraz CHIC spółka z ograniczoną odpowiedzialnością sp. k. z siedzibą w Ostrzeszowie (CHIC). Twoje dane będą przewarzane wyłącznie w tych celach, na które wyraziłeś/aś swoją zgodę powyżej.</p>
        <p className="consent-text">Możesz się z nami skontaktować pisząc na adres poczty elektronicznej daneosobowe@bat.com.pl lub listownie na adres British American Tobacco Polska Trading Spółka z o.o., ul. Krakowiaków 48, 02-255 Warszawa.</p>
        <p className="consent-text">W związku z podaniem danych osobowych przysługuje Ci prawo do uzyskania dostępu do treści Twoich danych, prawo ich poprawiania, prawo do ich przenoszenia, prawo do żądania ich usunięcia oraz prawo ograniczenia ich przetwarzania. Jeśli uznasz, że przetwarzamy Twoje dane osobowe niezgodnie z prawem, możesz wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych.</p>
        <p className="consent-text">Więcej informacji o ochronie Twoich danych osobowych oraz o zasadach wspólnego nimi administrowania przez BAT oraz CHIC znajdziesz w naszej <a href="https://www.discoverglo.com/pl/pl/polityka-prywatnosci" target="_blank" rel="noopener noreferrer">Polityce Prywatności</a></p>

        <FixedButton>
          <PrevButton />
          <Submit label={"Zarejestruj"} isLoading={isLoading || isLoadingReceipt} />
          {noOTPAction && <Submit label={"Zarejestruj bez OTP"} isLoading={isLoading || isLoadingReceipt} />}
        </FixedButton>
      </Form>
    </section >
  )
}
